/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

@font-face {
  font-family: 'Techni';  //This is what we are going to call
  src: url('./assets/fonts/AR\ Techni.ttf');
}

*{
  font-family: Techni !important;


  --c-seagreen: #38AAA8;
  --c-seagreenOpacity: #38aaa870;
  --c-red: #EB194D;
  --c-davygray: #5B5150;
  --c-davygrayPress: #887978;
  --c-yellow: #F5DE66;
  --c-yellowOpacity: #F5DE6670;
  --c-violet: #A91F6B;
  --c-greenwhite: #EDEDE3;
  --c-white: #FFFFFF;
  --c-disabledBg: rgb(230, 230, 230);
  --c-disableColor: rgb(163, 163, 163);
  --c-disableBorder: rgb(175, 175, 175);
  --c-blackOpacity: rgba(0, 0, 0, 0.3);
}

@keyframes dropdown-in {
  0%{
  }
  100%{
    height: 60px;
    padding: 15px 0px 15px 0px;
  }
}

@keyframes dropdown-out {
  0%{
    height: 60px;
    padding: 15px 0px 15px 0px;
  }
  100%{
    padding: 0px 0px 0px 0px;
    visibility: hidden;
  }
}

@keyframes dropdown-msg {
  0%{
    height: 0px;
  }
  100%{
    padding: 7px 5px 10px 5px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

@keyframes dropdown-inside-in{
  0%{
    height: 0px;
  }
  100%{
  }
}

@keyframes dropdown-inside-out{
  0%{
  }
  100%{
    height: 0px;
  }
}

@keyframes toRed {
  0%   { background: var(--c-seagreen); }
  50%{ background: rgb(185, 115, 115);}
  100% { background: var(--c-red); }
}

@keyframes toGreen {
  0%   { background: var(--c-red); }
  50%{ background: rgb(99, 158, 158);}
  100% { background: var(--c-seagreen); }
}

@keyframes push-out{
  100%  {
    transform: scale(0);
    visibility: hidden;
  }
}

@keyframes pop{
  0%{
    opacity: 0;
  }
  50%  {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes fade-out{
  100%  {
    opacity: 0;
    visibility: hidden;
  }
}

.shadow {
  -moz-box-shadow:     3px 3px 12px -6px #000000;
  -webkit-box-shadow:  3px 3px 12px -6px #000000;
  box-shadow:          3px 3px 12px -6px #000000;
}

.header{
  padding-top:12px;
  height: 75px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  .logo{
    height: 50px;
    content: url(./assets/img/logo_leonardo_BIANCO.png);
    margin: auto;
  }

  ion-buttons{
    margin: 0px 30px;

    ion-icon, ion-text{
      margin: 0px 5px;
    }
  }
}

ion-app, ion-content, ion-toolbar{
  --background: transparent !important;
}

body{
  --background: transparent !important;
  background-image: url('./assets/img/Sfondo_Distribution.png');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

ion-card{
  background-color: white;
  height: 13rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
}

ion-card-header{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

ion-card-title, ion-card-subtitle{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin: 10px 0px;
  color: #fff;
}

ion-card-title{
  font-size: 28px;
}

.swiper-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icona{
  width: 2.2rem;
  margin: 5px;
}

.bottone{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 26vh !important;
  width: 32vh !important;
  border-radius: 2rem !important;
  transition: transform 125ms ease-in-out;
  margin:10px 70px;

  h3 {
    text-align: center;
  }

}

.bottone:hover{
  transform: scale(1.075);
}

.boysenberry {
  color: white;
  background-color: var(--ion-color-boysenberry);
}

.opal {
  color: white;
  background-color: var(--ion-color-opal);
}

.tertiary {
  color: white;
  background-color: var(--ion-color-tertiary);
}

.titolo{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.evento-modal {
  --width: 100%;
  --min-height: 100%;
  align-items: flex-end;
}

.powered-by{
  width: 200px;
  content: url(./assets/img/Logo_prospettive_powered.png);
}

.loader-hide{
  animation: push-out .4s ease-in-out;
}

.loader-show{
  visibility: visible;
  animation: pop .4s ease-in-out 1;
}

.loader-container-hide{
  animation: fade-out .4s ease-in-out 1;
  animation-fill-mode: forwards;
}

.loader{
  z-index: 4;
  height: 120px;
  width: 200px;
  background-color: var(--ion-color-danger);
  color:var(--c-white);
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 15px;
}

.loader-container{
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0px;
  left: 0px;
  background: var(--c-blackOpacity);
}

.hidden{
  visibility: hidden;
}

.loader-text{
  margin: 10px;
  margin-bottom: -5px;
  padding: 0;
  text-align: center;
}

.alert-login{
  --background: white !important;
}

.powered{
  color: black;
  position: fixed;
  padding: 10px 10px;
  margin: 2px 5px;
  bottom: 0;
  right: 0;
}

.app-version{
  color: black;
  position: fixed;
  padding: 12px 20px;
  bottom: 0;
  left: 0;
}

.vota-icon{
  content: url(./assets/icon/ICONA_VotaLeonardo.png);
}

.iniziative-icon{
  content: url(./assets/icon/ICONA_Iniziative.png);
}

.star-icon{
  content: url(./assets/icon/ICONA_Stella.png);
}

.newsletter-icon{
  content: url(./assets/icon/ICONA_Newsletter.png);
}
