// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
	--ion-color-primary: #EB194D;
	--ion-color-primary-rgb: 235,25,77;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #cf1644;
	--ion-color-primary-tint: #ed305f;

  /** secondary **/
	--ion-color-secondary: #F5DE66;
	--ion-color-secondary-rgb: 245,222,102;
	--ion-color-secondary-contrast: #A91F6B;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #d8c35a;
	--ion-color-secondary-tint: #f6e175;

  /** tertiary **/
  --ion-color-tertiary: #38AAA8;
	--ion-color-tertiary-rgb: 56,170,168;
	--ion-color-tertiary-contrast: #EDEDE3;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #319694;
	--ion-color-tertiary-tint: #4cb3b1;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
	--ion-color-danger: #A91F6B;
	--ion-color-danger-rgb: 169,31,107;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #951b5e;
	--ion-color-danger-tint: #b2357a;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

	--ion-color-boysenberry: #A91F6B;
	--ion-color-boysenberry-rgb: 169,31,107;
	--ion-color-boysenberry-contrast: #ffffff;
	--ion-color-boysenberry-contrast-rgb: 255,255,255;
	--ion-color-boysenberry-shade: #951b5e;
	--ion-color-boysenberry-tint: #b2357a;

  --ion-color-tangored: #EB194D;
  --ion-color-tangored-rgb: 235,25,77;
  --ion-color-tangored-contrast: #ffffff;
  --ion-color-tangored-contrast-rgb: 255,255,255;
  --ion-color-tangored-shade: #cf1644;
  --ion-color-tangored-tint: #ed305f;

	--ion-color-sherwin: #38AAA8;
	--ion-color-sherwin-rgb: 56,170,168;
  --ion-color-sherwin-contrast: #ffffff;
  --ion-color-sherwin-contrast-rgb: 255,255,255;
	--ion-color-sherwin-shade: #319694;
	--ion-color-sherwin-tint: #4cb3b1;

  --ion-color-opal: #EC5C51;
  --ion-color-opal-rgb: 236,92,81;
  --ion-color-opal-contrast: #ffffff;
  --ion-color-opal-contrast-rgb: 255,255,255;
  --ion-color-opal-shade: #d05147;
  --ion-color-opal-tint: #ee6c62;

	--ion-color-yellow: #F5DE66;
	--ion-color-yellow-rgb: 245,222,102;
	--ion-color-yellow-contrast: #ffffff;
	--ion-color-yellow-contrast-rgb: 255,255,255;
	--ion-color-yellow-shade: #d8c35a;
	--ion-color-yellow-tint: #f6e175;

  --ion-color-lilac: #BBA2CA;
	--ion-color-lilac-rgb: 187,162,202;
	--ion-color-lilac-contrast: #ffffff;
	--ion-color-lilac-contrast-rgb: 255,255,255;
	--ion-color-lilac-shade: #a58fb2;
	--ion-color-lilac-tint: #c2abcf;

  --ion-color-wenge: #5B5150;
	--ion-color-wenge-rgb: 91,81,80;
	--ion-color-wenge-contrast: #ffffff;
	--ion-color-wenge-contrast-rgb: 255,255,255;
	--ion-color-wenge-shade: #504746;
	--ion-color-wenge-tint: #6b6262;
}

.ion-color-wenge {
	--ion-color-base: var(--ion-color-wenge);
	--ion-color-base-rgb: var(--ion-color-wenge-rgb);
	--ion-color-contrast: var(--ion-color-wenge-contrast);
	--ion-color-contrast-rgb: var(--ion-color-wenge-contrast-rgb);
	--ion-color-shade: var(--ion-color-wenge-shade);
	--ion-color-tint: var(--ion-color-wenge-tint);
}

.ion-color-lilac {
	--ion-color-base: var(--ion-color-lilac);
	--ion-color-base-rgb: var(--ion-color-lilac-rgb);
	--ion-color-contrast: var(--ion-color-lilac-contrast);
	--ion-color-contrast-rgb: var(--ion-color-lilac-contrast-rgb);
	--ion-color-shade: var(--ion-color-lilac-shade);
	--ion-color-tint: var(--ion-color-lilac-tint);
}

.ion-color-yellow {
	--ion-color-base: var(--ion-color-yellow);
	--ion-color-base-rgb: var(--ion-color-yellow-rgb);
	--ion-color-contrast: var(--ion-color-yellow-contrast);
	--ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
	--ion-color-shade: var(--ion-color-yellow-shade);
	--ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-opal {
  --ion-color-base: var(--ion-color-opal);
  --ion-color-base-rgb: var(--ion-color-opal-rgb);
  --ion-color-contrast: var(--ion-color-opal-contrast);
  --ion-color-contrast-rgb: var(--ion-color-opal-contrast-rgb);
  --ion-color-shade: var(--ion-color-opal-shade);
  --ion-color-tint: var(--ion-color-opal-tint);
}

.ion-color-sherwin {
	--ion-color-base: var(--ion-color-sherwin);
	--ion-color-base-rgb: var(--ion-color-sherwin-rgb);
	--ion-color-contrast: var(--ion-color-sherwin-contrast);
	--ion-color-contrast-rgb: var(--ion-color-sherwin-contrast-rgb);
	--ion-color-shade: var(--ion-color-sherwin-shade);
	--ion-color-tint: var(--ion-color-sherwin-tint);
}

.ion-color-tangored {
  --ion-color-base: var(--ion-color-tangored);
  --ion-color-base-rgb: var(--ion-color-tangored-rgb);
  --ion-color-contrast: var(--ion-color-tangored-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tangored-contrast-rgb);
  --ion-color-shade: var(--ion-color-tangored-shade);
  --ion-color-tint: var(--ion-color-tangored-tint);
}

.ion-color-boysenberry {
	--ion-color-base: var(--ion-color-boysenberry);
	--ion-color-base-rgb: var(--ion-color-boysenberry-rgb);
	--ion-color-contrast: var(--ion-color-boysenberry-contrast);
	--ion-color-contrast-rgb: var(--ion-color-boysenberry-contrast-rgb);
	--ion-color-shade: var(--ion-color-boysenberry-shade);
	--ion-color-tint: var(--ion-color-boysenberry-tint);
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #EB194D;
//     --ion-color-primary-rgb: 235,25,77;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255,255,255;
//     --ion-color-primary-shade: #cf1644;
//     --ion-color-primary-tint: #ed305f;

//     --ion-color-secondary: #F5DE66;
//     --ion-color-secondary-rgb: 245,222,102;
//     --ion-color-secondary-contrast: #A91F6B;
//     --ion-color-secondary-contrast-rgb: 0,0,0;
//     --ion-color-secondary-shade: #d8c35a;
//     --ion-color-secondary-tint: #f6e175;

//     --ion-color-tertiary: #38AAA8;
//     --ion-color-tertiary-rgb: 56,170,168;
//     --ion-color-tertiary-contrast: #EDEDE3;
//     --ion-color-tertiary-contrast-rgb: 0,0,0;
//     --ion-color-tertiary-shade: #319694;
//     --ion-color-tertiary-tint: #4cb3b1;

//     --ion-color-success: #2dd36f;
//     --ion-color-success-rgb: 45,211,111;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #28ba62;
//     --ion-color-success-tint: #42d77d;

//     --ion-color-warning: #ffc409;
//     --ion-color-warning-rgb: 255,196,9;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0ac08;
//     --ion-color-warning-tint: #ffca22;

//     --ion-color-danger: #A91F6B;
//     --ion-color-danger-rgb: 169,31,107;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #951b5e;
//     --ion-color-danger-tint: #b2357a;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244,245,248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0,0,0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152,154,162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34,36,40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255,255,255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0,0,0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }


//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18,18,18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }
